import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { GuestList } from 'types';
import { apiCreateGuestLists, apiDeleteGuestLists, apiEditGuestList, apiGetGuestLists } from 'api/guestLists';
import { setHeaderTitle, setShowEventExpirePopup } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import { EventStatus } from 'constant';

const AdminGuestList = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const activeEvent = useAppSelector(state => state.events.activeEvent);

	const [all, setAll] = useState<GuestList | null>(null);
	const [lists, setLists] = useState<GuestList[]>([]);

	const [deletingList, setDeletingList] = useState<GuestList | null>(null);
	const [deletingListChecked, setDeletingListChecked] = useState<boolean>(false);
	const [editingList, setEditingList] = useState<GuestList | null>(null);
	const [editingListName, setEditingListName] = useState<string>('');

	const [createListModalShow, setCreateListModalShow] = useState(false);
	const [guestLists, setGuestLists] = useState<string[]>([]);

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Guest List'))
		})
	}, [])

	useEffect(() => {
		(async () => {
			if (activeEvent) {
				const response = await apiGetGuestLists(activeEvent.id)

				if (response.success) {
					setAll(response.data.all)
					setLists(response.data.lists)
				}
			}
		})()
	}, [activeEvent])


	useEffect(() => {
		setGuestLists(['']);
	}, [createListModalShow])


	const handleDecrement = (index: number) => {
		if (guestLists.length <= 1) {
			return;
		}
		setGuestLists([...guestLists.filter((g, i) => i !== index)])
	}
	const handleIncrement = () => {
		setGuestLists([...guestLists, '']);
	}

	const handleCreateGuestLists = async () => {
		console.log(guestLists)
		if (!activeEvent) {
			return;
		}

		let guest_lists: any = {};
		guestLists.forEach((g, i) => {
			if (g !== '') {
				guest_lists[`guest_lists[${i}]`] = g;
			}
		})

		const listResponse = await apiCreateGuestLists(activeEvent.id, guest_lists);

		if (listResponse.success) {
			setCreateListModalShow(false);

			const response = await apiGetGuestLists(activeEvent.id)
			
			if (response.success) {
				setAll(response.data.all)
				setLists(response.data.lists)
			}
		}
	}


	const handleDeleteList = async () => {
		if (deletingList && deletingList.id) {
			
			await apiDeleteGuestLists(deletingList.id);
			
			setDeletingList(null);
			setDeletingListChecked(false);

			if (activeEvent) {
				const response = await apiGetGuestLists(activeEvent.id)
				
				if (response.success) {
					setAll(response.data.all)
					setLists(response.data.lists)
				}
			}
		}
	}

	const handleEditList = async () => {
		if (editingList && editingList.id && editingListName && editingListName != '') {
			
			await apiEditGuestList(editingList.id, editingListName);
			
			setEditingList(null);
			setEditingListName('');
			if (activeEvent) {
				const response = await apiGetGuestLists(activeEvent.id)
				
				if (response.success) {
					setAll(response.data.all)
					setLists(response.data.lists)
				}
			}
		}
	}


	if (!activeEvent) {
		return <Navigate to="/" />
	}

	return (
		<>
			<div className="cp_table cp_wdth">
				<table className="table table-borderless">
					<thead>
						<tr>
							<th style={{width: '40%'}}>Full List</th>
							<th style={{width: '5%'}}></th>
							<th style={{width: '10%'}}>Total</th>
							<th style={{width: '10%'}}>Unconfirmed</th>
							<th style={{width: '10%'}}>Confirmed</th>
							<th style={{width: '10%'}}>Checked In</th>
							<th style={{width: '15%'}}></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td 
								onClick={() => navigate(`/guest-list/view`, {state: all})} 
								style={{
									cursor: 'pointer',
									width: '40%'
								}} 
							>{all?.list_name}</td>
							<td style={{width: '5%'}}></td>
							<td style={{width: '10%'}}>{all?.total}</td>
							<td style={{width: '10%'}}>{all?.unconfirmed}</td>
							<td style={{width: '10%'}}>{all?.confirmed}</td>
							<td style={{width: '10%'}}>{all?.checked_in}</td>
							<td style={{width: '15%'}}>
								<Link to={`/download-guest?list=ALL`}>
									<svg width="62" height="60" viewBox="0 0 72 70" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g filter="url(#filter0_d_648_1623)">
											<rect x="16" y="10" width="40" height="40" rx="4" fill="white" />
											<rect x="16.5" y="10.5" width="39" height="39" rx="3.5" stroke="#CFC8CF" />
										</g>
										<path d="M39.5 23.9365C41.5923 25.1468 43 27.409 43 30C43 33.866 39.866 37 36 37C32.134 37 29 33.866 29 30C29 27.409 30.4077 25.1468 32.5 23.9365M33.2 30L36 32.8M36 32.8L38.8 30M36 32.8V23" stroke="#2C292C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
										<defs>
											<filter id="filter0_d_648_1623" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
												<feFlood floodOpacity="0" result="BackgroundImageFix" />
												<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
												<feOffset dy="6" />
												<feGaussianBlur stdDeviation="8" />
												<feComposite in2="hardAlpha" operator="out" />
												<feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.160784 0 0 0 0 0.172549 0 0 0 0.16 0" />
												<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_648_1623" />
												<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_648_1623" result="shape" />
											</filter>
										</defs>
									</svg>
								</Link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className='text-end mt-4 mb-4'>
			<Button className="btn" variant='dark' onClick={() => {
				if (activeEvent.status === EventStatus.ARCHIVED || activeEvent.status === EventStatus.EXPIRED) {
					dispatch(setShowEventExpirePopup(true))
				} else {
					setCreateListModalShow(true)
				}
			}}>
				<i className="ico">
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7 1V13M1 7H13" stroke="#FFFFFF" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
				</i>
				Create List
			</Button>
			</div>

			<div className="cp_table cp_wdth">
				<table className="table table-borderless" id='admin-guest-list-table'>
					<thead>
						<tr>
							<th style={{width: '40%'}}>Guest Lists</th>
							<th style={{width: '5%'}}></th>
							<th style={{width: '10%'}}>Total</th>
							<th style={{width: '10%'}}>Unconfirmed</th>
							<th style={{width: '10%'}}>Confirmed</th>
							<th style={{width: '10%'}}>Checked In</th>
							<th style={{width: '15%'}}></th>
						</tr>
					</thead>
					<tbody>
						{lists.map((list, i) => {
							return (
							<tr key={i}>
								<td 
									onClick={() => navigate(`/guest-list/view/${list.id}`, {state: list})} 
									style={{
										cursor: 'pointer',
										width: '40%',
									}} 
								>
									{list.list_name}
								</td>
								<td style={{width: '5%'}}>
									<Button variant='white' size='sm' className="edit-btn" onClick={() => {
										batch(() => {
											setEditingList(list)
											setEditingListName(list.list_name)
										})
									}}>
										<svg width="13" height="13" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M7.4 1L9 2.6M1 9L1.51056 7.12795C1.54387 7.00581 1.56053 6.94474 1.58609 6.88779C1.60879 6.83723 1.63669 6.78916 1.66933 6.74437C1.70609 6.69391 1.75084 6.64916 1.84036 6.55964L5.97373 2.42627C6.05293 2.34707 6.09253 2.30747 6.1382 2.29263C6.17837 2.27958 6.22163 2.27958 6.2618 2.29263C6.30747 2.30747 6.34707 2.34707 6.42627 2.42627L7.57373 3.57373C7.65293 3.65293 7.69253 3.69253 7.70737 3.7382C7.72042 3.77837 7.72042 3.82163 7.70737 3.8618C7.69253 3.90747 7.65293 3.94707 7.57373 4.02627L3.44036 8.15964C3.35084 8.24916 3.30609 8.29391 3.25563 8.33067C3.21084 8.36331 3.16277 8.39121 3.11221 8.41391C3.05526 8.43948 2.99419 8.45613 2.87206 8.48944L1 9Z" 
											stroke="#2C292C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>
									</Button>
								</td>
								<td style={{width: '10%'}}>{list.total}</td>
								<td style={{width: '10%'}}>{list.unconfirmed}</td>
								<td style={{width: '10%'}}>{list.confirmed}</td>
								<td style={{width: '10%'}}>{list.checked_in}</td>
								<td style={{width: '15%'}}>
									<div className="udd_btn">
										<Link to={`/upload-guest?list=${list.id}`}>
											<svg width="72" height="70" viewBox="0 0 72 70" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g filter="url(#filter0_d_648_1641)">
													<rect x="16" y="10" width="40" height="40" rx="4" fill="#2C292C" />
												</g>
												<path d="M39.5 36.0635C41.5923 34.8532 43 32.591 43 30C43 26.134 39.866 23 36 23C32.134 23 29 26.134 29 30C29 32.591 30.4077 34.8532 32.5 36.0635M33.2 30L36 27.2M36 27.2L38.8 30M36 27.2V37" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
												<defs>
													<filter id="filter0_d_648_1641" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
														<feFlood floodOpacity="0" result="BackgroundImageFix" />
														<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
														<feOffset dy="6" />
														<feGaussianBlur stdDeviation="8" />
														<feComposite in2="hardAlpha" operator="out" />
														<feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.160784 0 0 0 0 0.172549 0 0 0 0.16 0" />
														<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_648_1641" />
														<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_648_1641" result="shape" />
													</filter>
												</defs>
											</svg>
										</Link>

										<Link to={`/download-guest?list=${list.id}`}>
											<svg width="72" height="70" viewBox="0 0 72 70" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g filter="url(#filter0_d_648_1623)">
													<rect x="16" y="10" width="40" height="40" rx="4" fill="white" />
													<rect x="16.5" y="10.5" width="39" height="39" rx="3.5" stroke="#CFC8CF" />
												</g>
												<path d="M39.5 23.9365C41.5923 25.1468 43 27.409 43 30C43 33.866 39.866 37 36 37C32.134 37 29 33.866 29 30C29 27.409 30.4077 25.1468 32.5 23.9365M33.2 30L36 32.8M36 32.8L38.8 30M36 32.8V23" stroke="#2C292C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
												<defs>
													<filter id="filter0_d_648_1623" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
														<feFlood floodOpacity="0" result="BackgroundImageFix" />
														<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
														<feOffset dy="6" />
														<feGaussianBlur stdDeviation="8" />
														<feComposite in2="hardAlpha" operator="out" />
														<feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.160784 0 0 0 0 0.172549 0 0 0 0.16 0" />
														<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_648_1623" />
														<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_648_1623" result="shape" />
													</filter>
												</defs>
											</svg>
										</Link>

										<a href='javascript:void(0)' onClick={() => setDeletingList(list)}>
											<svg width="72" height="70" viewBox="0 0 72 70" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g filter="url(#filter0_d_612_466)">
													<rect x="16" y="10" width="40" height="40" rx="4" fill="white" />
													<rect x="16.5" y="10.5" width="39" height="39" rx="3.5" stroke="#CFC8CF" />
												</g>
												<path d="M39.1 25.8V25.24C39.1 24.4559 39.1 24.0639 38.9474 23.7644C38.8132 23.501 38.599 23.2868 38.3356 23.1526C38.0361 23 37.6441 23 36.86 23H35.74C34.9559 23 34.5639 23 34.2644 23.1526C34.001 23.2868 33.7868 23.501 33.6526 23.7644C33.5 24.0639 33.5 24.4559 33.5 25.24V25.8M34.9 29.65V33.15M37.7 29.65V33.15M30 25.8H42.6M41.2 25.8V33.64C41.2 34.8161 41.2 35.4042 40.9711 35.8534C40.7698 36.2485 40.4485 36.5698 40.0534 36.7711C39.6042 37 39.0161 37 37.84 37H34.76C33.5839 37 32.9958 37 32.5466 36.7711C32.1515 36.5698 31.8302 36.2485 31.6289 35.8534C31.4 35.4042 31.4 34.8161 31.4 33.64V25.8" stroke="#2C292C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
												<defs>
													<filter id="filter0_d_612_466" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
														<feFlood floodOpacity="0" result="BackgroundImageFix" />
														<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
														<feOffset dy="6" />
														<feGaussianBlur stdDeviation="8" />
														<feComposite in2="hardAlpha" operator="out" />
														<feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.160784 0 0 0 0 0.172549 0 0 0 0.16 0" />
														<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_612_466" />
														<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_612_466" result="shape" />
													</filter>
												</defs>
											</svg>
										</a>
									</div>
								</td>
							</tr>
							)
						})}
						
					</tbody>
				</table>
			</div>

			<Modal show={createListModalShow} className="createNewAccountModal" onHide={() => setCreateListModalShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
					Create Guest List
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form onSubmit={e => e.preventDefault()}>
						<div className={`step-form2 mb-5`}>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Guest Lists</Form.Label>

								{guestLists.map((guestList, index) => {
									return (
										<div className="inBox vip" key={index}>
											<Form.Control type="text" placeholder="e.g. VIP" value={guestList} autoFocus={true} onChange={e => {
												setGuestLists([...guestLists.map((g, i) => {
													if (i === index) {
														return e.target.value
													}
													return g;
												})])
											}} />
											<div className="btnGroup">
												<Button className="" onClick={() => handleDecrement(index)} disabled={index >= (guestLists.length - 1)}>
													<svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M0.757324 1H9.24261" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
													</svg>
												</Button>

												<Button className="" onClick={handleIncrement} disabled={index < (guestLists.length - 1)}>
													<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M4.99997 0.757359V9.24264M0.757324 5H9.24261" stroke="#8C838C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
													</svg>
												</Button>
											</div>
										</div>
									)
								})}
							</Form.Group>
						</div>

						<Button variant="primary" className={`btn-steps cp_btn_pop`} onClick={handleCreateGuestLists}>
							<span className={`step2 d-flex align-items-center justify-content-between`}>
								<span className="label">Create Guest Lists</span>
								<span className="arrow">
									<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</span>
							</span>
						</Button>

						<p className={`note`}>
							You can add a maximum of 20 Guest Lists at a time.
						</p>

					</Form>
				</Modal.Body>
			</Modal>

			
			{deletingList && (
			<Modal show={true} className="createNewAccountModal" onHide={() => setDeletingList(null)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>Delete Guest List</span>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form onSubmit={e => e.preventDefault()}>
						<div>
							<p className='text-start'>
								Are you sure you want to delete [{deletingList.list_name}] list with all of its guests
							</p>

							<div className="mb-5">
								<Form.Check id="delete-guest-list-check" 
									type="checkbox" 
									name="delete-guest-list-check" 
									label="I understand that this cannot be undone." 
									checked={deletingListChecked}
									onChange={() => setDeletingListChecked(!deletingListChecked)}
								/>
							</div>

						</div>

						<div className={`actionBtns`}>
							<Button type='button' variant="danger" className={`btn-steps cp_delete_btn`} onClick={handleDeleteList} disabled={!deletingListChecked}>
								<span className={`step3 d-flex align-items-center justify-content-center`}>
									DELETE GUEST LIST
								</span>
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
			)}
			
			{editingList && (
			<Modal show={true} className="createNewAccountModal" onHide={() => setEditingList(null)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>Edit Guest List</span>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form onSubmit={e => e.preventDefault()}>
						<Form.Group className="mb-3" controlId="eventCreateModal">
							<Form.Control type="text" placeholder="List Name" value={editingListName} onChange={e => setEditingListName(e.target.value)} />
						</Form.Group>

						<div className="text-end">
							<Button type='button' variant='dark' className='ps-4 pe-4' onClick={handleEditList}>Save</Button>
						</div>
						
					</Form>
				</Modal.Body>
			</Modal>
			)}
		</>
	);
};

export default AdminGuestList;