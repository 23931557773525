import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventCardStyle } from 'constant';
import { ReactElement } from 'react';
import { EventCardStyleType, InterfaceManager } from 'types';


const initialState: InterfaceManager = {
	isOnline: true,
	headerTitle: '',
	lastPage: '',
	footerMessage: '',
	addGuestPopup: false,
	showEventExpirePopup: false,
	showScanner: false,
	eventCardStyle: EventCardStyle.GRID,
	adminEventCardStyle: EventCardStyle.GRID,
	selectDevicePopup: false,
	bulkBadgePrintPopup: false,
}

export const interfaceMangerSlice = createSlice({
	name: 'interfaceManger',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setOnline(state: InterfaceManager, action: PayloadAction<boolean>) {
			state.isOnline = action.payload;
		},
		setHeaderTitle(state: InterfaceManager, action: PayloadAction<string>) {
			state.headerTitle = action.payload;
		},
		setLastPage(state: InterfaceManager, action: PayloadAction<string>) {
			state.lastPage = action.payload;
		},
		setFooterMessage(state: InterfaceManager, action: PayloadAction<string | ReactElement>) {
			state.footerMessage = action.payload;
		},
		toggleAddGuestPopup(state: InterfaceManager, action: PayloadAction<boolean>) {
			state.addGuestPopup = action.payload;
		},
		setShowEventExpirePopup(state: InterfaceManager, action: PayloadAction<boolean>) {
			state.showEventExpirePopup = action.payload;
		},
		setShowScanner(state: InterfaceManager, action: PayloadAction<boolean>) {
			state.showScanner = action.payload;
		},
		setEventCardStyle(state: InterfaceManager, action: PayloadAction<EventCardStyleType>) {
			state.eventCardStyle = action.payload;
		},
		setAdminEventCardStyle(state: InterfaceManager, action: PayloadAction<EventCardStyleType>) {
			state.adminEventCardStyle = action.payload;
		},
		toggleSelectDevicePopup(state: InterfaceManager, action: PayloadAction<boolean>) {
			state.selectDevicePopup = action.payload;
		},
		toggleBulkBadgePrintPopup(state: InterfaceManager, action: PayloadAction<boolean>) {
			state.bulkBadgePrintPopup = action.payload;
		},
	},
});


export const {
	setOnline,
	setHeaderTitle,
	setLastPage,
	setFooterMessage,
	toggleAddGuestPopup,
	setShowEventExpirePopup,
	setShowScanner,
	setEventCardStyle,
	setAdminEventCardStyle,
	toggleSelectDevicePopup,
	toggleBulkBadgePrintPopup,
} = interfaceMangerSlice.actions;

export default interfaceMangerSlice.reducer;
