import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Event, EventStatusType } from 'types';
import { apiDeleteEvent, apiGetEvent, apiGetEventCollaborate, apiUpdateEventStatus } from 'api/events';
import dayjs from 'dayjs';
import EventCreate from 'views/Admin/components/EventCreate';
import { capitalizeWord, EventCardStyle, EventStatus, TeamAccess } from 'constant';
import { setActiveEvent, setEditEvent, unsetActiveEvent } from 'redux/eventSlice';
import { setHeaderTitle } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { apiLeaveTeamEvent } from 'api/team';
import EventPurchase from 'views/Admin/components/EventPurchase';
import { Image } from 'react-bootstrap';

const Dashboard = () => {
	const { logout, switchApp } = useContext(AuthContext);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userData = useAppSelector(state => state.auth.userData);
	const eventsListStatus = useAppSelector(state => state.events.eventsListStatus);
	const editEvent = useAppSelector(state => state.events.editEvent);
	const adminEventCardStyle = useAppSelector(state => state.interfaceManger.adminEventCardStyle);
	const [events, setEvents] = useState<Event[]>([]);
	const [eventsCollaborate, setEventsCollaborate] = useState<Event[]>([]);
	const [deletingEvent, setDeletingEvent] = useState<Event | null>(null);
	const [activatingEvent, setActivatingEvent] = useState<Event | null>(null);

	const [eventModalShow, setEventModalShow] = useState<boolean>(false);
	const [showEventShareModal, setShowEventShareModal] = useState<boolean>(false);

	const {height, width} = useWindowDimensions();

	let imgWidth = width - 270;

	if (width <= 992) {
		imgWidth = imgWidth - 30;
	}
	else {
		imgWidth = (imgWidth / 4) - 30;
	}

	useEffect(() => {
		batch(() => {
			dispatch(unsetActiveEvent())
			dispatch(setHeaderTitle('Events'))
		})
	}, [])

	useEffect(() => {

		(async () => {
			const response = await apiGetEvent(eventsListStatus)

			if (response.success) {
				setEvents(response.data)
			}
		})()

	}, [editEvent, eventsListStatus])

	useEffect(() => {

		(async () => {
			const response = await apiGetEventCollaborate(eventsListStatus)

			if (response.success) {
				setEventsCollaborate([...response.data.filter(e => e.member_access === TeamAccess.BACKEND)])
			}
		})()

	}, [editEvent, eventsListStatus])

	const updateEventStatus = async (eventId: number, status: EventStatusType) => {
		await apiUpdateEventStatus(eventId, status);
		onEventCreated();
	}

	const onEventCreated = async () => {
		const response = await apiGetEvent(eventsListStatus)

		if (response.success) {
			setEvents(response.data)
		}
	}

	const deleteEvent = async (event: Event) => {
		await apiDeleteEvent(event.id);
		setDeletingEvent(null);
		onEventCreated();
	}

	const leaveEvent = async (event: Event) => {
		await apiLeaveTeamEvent(event.id);
		
		const response = await apiGetEventCollaborate(eventsListStatus)

		if (response.success) {
			setEventsCollaborate([...response.data.filter(e => e.member_access === TeamAccess.BACKEND)])
		}
	}

	return (
		<>
			<div className='dashboardPage'>
				{(adminEventCardStyle === EventCardStyle.GRID) ? (
					<>
						<h4 className='mb-4 mt-3 ps-4'>My Events</h4>
						<Row>
							<Col lg="3" className='mb-4'>
								<div className="createNewEvent" onClick={() => {setEventModalShow(true)}}>
									<div className="content">
										<figure>
											<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M124 58H16M94 10V34M46 10V34M52 94L64 106L91 79M44.8 130H95.2C105.281 130 110.321 130 114.172 128.038C117.559 126.312 120.312 123.559 122.038 120.172C124 116.321 124 111.281 124 101.2V50.8C124 40.7191 124 35.6786 122.038 31.8282C120.312 28.4413 117.559 25.6876 114.172 23.9619C110.321 22 105.281 22 95.2 22H44.8C34.7191 22 29.6786 22 25.8282 23.9619C22.4413 25.6876 19.6876 28.4413 17.9619 31.8282C16 35.6786 16 40.7191 16 50.8V101.2C16 111.281 16 116.321 17.9619 120.172C19.6876 123.559 22.4413 126.312 25.8282 128.038C29.6786 130 34.7191 130 44.8 130Z" stroke="#CFC8CF" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
											</svg>
										</figure>

										<h3>
											Create<br />
											new event
										</h3>
									</div>
								</div>
							</Col>

							{events.map((event, index) => {
								return (
									<Col key={index} lg="3" className='mb-4'>
										<div className="card eventcard">
											<div className='card-img-top' style={{width: imgWidth, height: imgWidth}} onClick={() => {
												dispatch(setActiveEvent(event))
												navigate('/guest-list')
											}}>
												<img src={(event.logo) ? event.logo : require('assets/images/placeholder-event.jpg')} alt="event logo" />
											</div>
											<div className={`card-body ${event.status}`}>
												<Row>
													<Col>
														<div className="statusBox ">
															<span className="dot"></span>
															<span className="label">
																{capitalizeWord(event.status)}
															</span>
														</div>
													</Col>
													<Col className='text-end'>
														<Button type='button' variant='link' className='mt-2' size='sm' onClick={() => setShowEventShareModal(true)}>
															<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 48 48">
																<path d="M 35.478516 5.9804688 A 2.0002 2.0002 0 0 0 34.085938 9.4140625 L 35.179688 10.507812 C 23.476587 10.680668 14 20.256715 14 32 A 2.0002 2.0002 0 1 0 18 32 C 18 22.427546 25.627423 14.702715 35.154297 14.517578 L 34.085938 15.585938 A 2.0002 2.0002 0 1 0 36.914062 18.414062 L 41.236328 14.091797 A 2.0002 2.0002 0 0 0 41.228516 10.900391 L 36.914062 6.5859375 A 2.0002 2.0002 0 0 0 35.478516 5.9804688 z M 12.5 6 C 8.9338464 6 6 8.9338464 6 12.5 L 6 35.5 C 6 39.066154 8.9338464 42 12.5 42 L 35.5 42 C 39.066154 42 42 39.066154 42 35.5 L 42 28 A 2.0002 2.0002 0 1 0 38 28 L 38 35.5 C 38 36.903846 36.903846 38 35.5 38 L 12.5 38 C 11.096154 38 10 36.903846 10 35.5 L 10 12.5 C 10 11.096154 11.096154 10 12.5 10 L 20 10 A 2.0002 2.0002 0 1 0 20 6 L 12.5 6 z" fill='#2C292C'></path>
															</svg>
														</Button>
													</Col>
												</Row>

												<h5 className="card-title">{event.name}</h5>

												<p className="card-text">
													<i>
														<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.8 5.8H1M8.8 1V3.4M4 1V3.4M3.88 13H8.92C9.92809 13 10.4321 13 10.8172 12.8038C11.1559 12.6312 11.4312 12.3559 11.6038 12.0172C11.8 11.6321 11.8 11.1281 11.8 10.12V5.08C11.8 4.07191 11.8 3.56786 11.6038 3.18282C11.4312 2.84413 11.1559 2.56876 10.8172 2.39619C10.4321 2.2 9.9281 2.2 8.92 2.2H3.88C2.87191 2.2 2.36786 2.2 1.98282 2.39619C1.64413 2.56876 1.36876 2.84413 1.19619 3.18282C1 3.56786 1 4.07191 1 5.08V10.12C1 11.1281 1 11.6321 1.19619 12.0172C1.36876 12.3559 1.64413 12.6312 1.98282 12.8038C2.36786 13 2.87191 13 3.88 13Z" stroke="#CFC8CF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
														</svg>
													</i> 
													{dayjs(event.start_data).format('MMM DD, YYYY')} - {dayjs(`${event.start_data} ${event.time}`).format('h:mm a')}
												</p>

												<p className="card-text">
													<i>
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M12.8421 10.5523C15.0727 10.9908 16.6316 11.9924 16.6316 13.1579C16.6316 14.7275 13.8039 16 10.3158 16C6.82768 16 4 14.7275 4 13.1579C4 11.9924 5.55886 10.9908 7.78947 10.5523M10.3158 12.8421V7.78947M10.3158 7.78947C11.3622 7.78947 12.2105 6.94117 12.2105 5.89474C12.2105 4.8483 11.3622 4 10.3158 4C9.26936 4 8.42105 4.8483 8.42105 5.89474C8.42105 6.94117 9.26936 7.78947 10.3158 7.78947Z" stroke="#CFC8CF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
														</svg>
													</i> 
													{event.location}
												</p>

											</div>
											<div className={`card-body ${event.status}`} style={{flex: 1}}>
												<div className="btnGroups">
													<Link to="/guest-list" className='btn-upload' onClick={() => {
															dispatch(setActiveEvent(event))
														}}>
														<i>
															<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M5.5 9L5.5 1M5.5 1L1.5 5M5.5 1L9.5 5" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
															</svg>
														</i>

														<span className="label">
															Upload Guests
														</span>
													</Link>

													<DropdownButton
														title=''
														id="dropdown-menu-align-responsive-1"
														align={{ lg: 'end' }}
													>
														<Dropdown.Item eventKey="1"  onClick={() => {
															dispatch(setEditEvent(event))
														}}>
															<i>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M13.6 4L16 6.4M4 16L4.76584 13.1919C4.81581 13.0087 4.84079 12.9171 4.87914 12.8317C4.91319 12.7558 4.95503 12.6837 5.00399 12.6166C5.05913 12.5409 5.12627 12.4737 5.26054 12.3395L11.4606 6.13941C11.5794 6.02061 11.6388 5.9612 11.7073 5.93895C11.7675 5.91937 11.8325 5.91937 11.8927 5.93895C11.9612 5.9612 12.0206 6.02061 12.1394 6.13941L13.8606 7.86059C13.9794 7.97939 14.0388 8.0388 14.0611 8.1073C14.0806 8.16755 14.0806 8.23245 14.0611 8.29271C14.0388 8.3612 13.9794 8.42061 13.8606 8.53941L7.66054 14.7395C7.52627 14.8737 7.45913 14.9409 7.38345 14.996C7.31625 15.045 7.24415 15.0868 7.16831 15.1209C7.08289 15.1592 6.99129 15.1842 6.80808 15.2342L4 16Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																</svg>
															</i>
															<span className="label">
																Edit Event Details
															</span>
														</Dropdown.Item>

														{event.status !== EventStatus.ACTIVE ? (
														<Dropdown.Item eventKey="4" onClick={() => setActivatingEvent(event)}>
															<i>
																<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M4.85 8L6.95 10.1L11.15 5.9M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																</svg>
															</i>
															<span className="label">
																Activate Event
															</span>
														</Dropdown.Item>
														) : null}

														<Link to="/download-guest" className='dropdown-item' onClick={() => {
															dispatch(setActiveEvent(event))
														}}>
															<i>
																<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M10 1.80269C11.7934 2.84012 13 4.77915 13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 4.77915 2.2066 2.84012 4 1.80269M4.6 7L7 9.4M7 9.4L9.4 7M7 9.4V1" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																</svg>
															</i>
															<span className="label">
																Download Report
															</span>
														</Link>
														
														<Link to="/team" className='dropdown-item'>
															<i>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M14.3333 16V12M12.3333 14H16.3333M9.66667 12H7C5.75749 12 5.13623 12 4.64618 12.203C3.99277 12.4736 3.47364 12.9928 3.20299 13.6462C3 14.1362 3 14.7575 3 16M12 4.19384C12.9773 4.58943 13.6667 5.54754 13.6667 6.66667C13.6667 7.78579 12.9773 8.7439 12 9.13949M10.6667 6.66667C10.6667 8.13943 9.47276 9.33333 8 9.33333C6.52724 9.33333 5.33333 8.13943 5.33333 6.66667C5.33333 5.19391 6.52724 4 8 4C9.47276 4 10.6667 5.19391 10.6667 6.66667Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																</svg>
															</i>
															<span className="label">
																Add Team Member
															</span>
														</Link>
														{event.status !== EventStatus.ARCHIVED && event.status !== EventStatus.EXPIRED ? (
														<Dropdown.Item eventKey="4" onClick={() => updateEventStatus(event.id, EventStatus.ARCHIVED)}>
															<i>
																<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M12.1 4V8.92C12.1 9.92809 12.1 10.4321 11.9038 10.8172C11.7312 11.1559 11.4559 11.4312 11.1172 11.6038C10.7321 11.8 10.2281 11.8 9.22 11.8H4.78C3.77191 11.8 3.26786 11.8 2.88282 11.6038C2.54413 11.4312 2.26876 11.1559 2.09619 10.8172C1.9 10.4321 1.9 9.92809 1.9 8.92V4M1.96 1H12.04C12.376 1 12.544 1 12.6724 1.0654C12.7853 1.12292 12.8771 1.21471 12.9346 1.32761C13 1.45595 13 1.62397 13 1.96V3.04C13 3.37603 13 3.54405 12.9346 3.67239C12.8771 3.78529 12.7853 3.87708 12.6724 3.9346C12.544 4 12.376 4 12.04 4H1.96C1.62397 4 1.45595 4 1.32761 3.9346C1.21471 3.87708 1.12292 3.78529 1.0654 3.67239C1 3.54405 1 3.37603 1 3.04V1.96C1 1.62397 1 1.45595 1.0654 1.32761C1.12292 1.21471 1.21471 1.12292 1.32761 1.0654C1.45595 1 1.62397 1 1.96 1ZM5.56 6.1H8.44C8.77603 6.1 8.94405 6.1 9.07239 6.1654C9.18529 6.22292 9.27708 6.31471 9.3346 6.42761C9.4 6.55595 9.4 6.72397 9.4 7.06V7.54C9.4 7.87603 9.4 8.04405 9.3346 8.17239C9.27708 8.28529 9.18529 8.37708 9.07239 8.4346C8.94405 8.5 8.77603 8.5 8.44 8.5H5.56C5.22397 8.5 5.05595 8.5 4.92761 8.4346C4.81471 8.37708 4.72292 8.28529 4.6654 8.17239C4.6 8.04405 4.6 7.87603 4.6 7.54V7.06C4.6 6.72397 4.6 6.55595 4.6654 6.42761C4.72292 6.31471 4.81471 6.22292 4.92761 6.1654C5.05595 6.1 5.22397 6.1 5.56 6.1Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																</svg>
															</i>
															<span className="label">
																Archive Event
															</span>
														</Dropdown.Item>
														) : null}
														<Dropdown.Item eventKey="4" onClick={() => setDeletingEvent(event)}>
															<i>
																<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M8.8 3.4V2.92C8.8 2.24794 8.8 1.91191 8.66921 1.65521C8.55416 1.42942 8.37058 1.24584 8.14479 1.13079C7.88809 1 7.55206 1 6.88 1H5.92C5.24794 1 4.91191 1 4.65521 1.13079C4.42942 1.24584 4.24584 1.42942 4.13079 1.65521C4 1.91191 4 2.24794 4 2.92V3.4M5.2 6.7V9.7M7.6 6.7V9.7M1 3.4H11.8M10.6 3.4V10.12C10.6 11.1281 10.6 11.6321 10.4038 12.0172C10.2312 12.3559 9.95587 12.6312 9.61718 12.8038C9.23214 13 8.72809 13 7.72 13H5.08C4.07191 13 3.56786 13 3.18282 12.8038C2.84413 12.6312 2.56876 12.3559 2.39619 12.0172C2.2 11.6321 2.2 11.1281 2.2 10.12V3.4" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																</svg>
															</i>
															<span className="label">
																Delete
															</span>
														</Dropdown.Item>
													</DropdownButton>
												</div>

											</div>
										</div>
									</Col>
								)
							})}
						</Row>

						<hr />
						<h4 className='mb-4 mt-3 ps-4'>Event Collaborations</h4>
						{(eventsCollaborate.length === 0) && (<p className='text-muted ps-4'>No events available!</p>)}
						<Row>
							{eventsCollaborate.map((event, index) => {
								return (
									<Col key={index} lg="3" className='mb-4'>
										<div className="card eventcard">
											<div className='card-img-top' style={{width: imgWidth, height: imgWidth}} onClick={() => {
												dispatch(setActiveEvent(event))
												navigate('/guest-list')
											}}>
												<img src={(event.logo) ? event.logo : require('assets/images/placeholder-event.jpg')} alt="event logo" />
											</div>
											<div className={`card-body ${event.status}`}>
												<Row>
													<Col>
														<div className="statusBox ">
															<span className="dot"></span>
															<span className="label">
																{capitalizeWord(event.status)}
															</span>
														</div>
													</Col>
													<Col className='text-end'>
														<Button type='button' variant='link' className='mt-2' size='sm' onClick={() => setShowEventShareModal(true)}>
															<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 48 48">
																<path d="M 35.478516 5.9804688 A 2.0002 2.0002 0 0 0 34.085938 9.4140625 L 35.179688 10.507812 C 23.476587 10.680668 14 20.256715 14 32 A 2.0002 2.0002 0 1 0 18 32 C 18 22.427546 25.627423 14.702715 35.154297 14.517578 L 34.085938 15.585938 A 2.0002 2.0002 0 1 0 36.914062 18.414062 L 41.236328 14.091797 A 2.0002 2.0002 0 0 0 41.228516 10.900391 L 36.914062 6.5859375 A 2.0002 2.0002 0 0 0 35.478516 5.9804688 z M 12.5 6 C 8.9338464 6 6 8.9338464 6 12.5 L 6 35.5 C 6 39.066154 8.9338464 42 12.5 42 L 35.5 42 C 39.066154 42 42 39.066154 42 35.5 L 42 28 A 2.0002 2.0002 0 1 0 38 28 L 38 35.5 C 38 36.903846 36.903846 38 35.5 38 L 12.5 38 C 11.096154 38 10 36.903846 10 35.5 L 10 12.5 C 10 11.096154 11.096154 10 12.5 10 L 20 10 A 2.0002 2.0002 0 1 0 20 6 L 12.5 6 z" fill='#2C292C'></path>
															</svg>
														</Button>
													</Col>
												</Row>

												<h5 className="card-title">{event.name}</h5>

												<p className="card-text">
													<i>
														<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M11.8 5.8H1M8.8 1V3.4M4 1V3.4M3.88 13H8.92C9.92809 13 10.4321 13 10.8172 12.8038C11.1559 12.6312 11.4312 12.3559 11.6038 12.0172C11.8 11.6321 11.8 11.1281 11.8 10.12V5.08C11.8 4.07191 11.8 3.56786 11.6038 3.18282C11.4312 2.84413 11.1559 2.56876 10.8172 2.39619C10.4321 2.2 9.9281 2.2 8.92 2.2H3.88C2.87191 2.2 2.36786 2.2 1.98282 2.39619C1.64413 2.56876 1.36876 2.84413 1.19619 3.18282C1 3.56786 1 4.07191 1 5.08V10.12C1 11.1281 1 11.6321 1.19619 12.0172C1.36876 12.3559 1.64413 12.6312 1.98282 12.8038C2.36786 13 2.87191 13 3.88 13Z" stroke="#CFC8CF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
														</svg>
													</i> 
													{dayjs(event.start_data).format('MMM DD, YYYY')} - {dayjs(`${event.start_data} ${event.time}`).format('h:mm a')}
												</p>

												<p className="card-text">
													<i>
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M12.8421 10.5523C15.0727 10.9908 16.6316 11.9924 16.6316 13.1579C16.6316 14.7275 13.8039 16 10.3158 16C6.82768 16 4 14.7275 4 13.1579C4 11.9924 5.55886 10.9908 7.78947 10.5523M10.3158 12.8421V7.78947M10.3158 7.78947C11.3622 7.78947 12.2105 6.94117 12.2105 5.89474C12.2105 4.8483 11.3622 4 10.3158 4C9.26936 4 8.42105 4.8483 8.42105 5.89474C8.42105 6.94117 9.26936 7.78947 10.3158 7.78947Z" stroke="#CFC8CF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
														</svg>
													</i> 
													{event.location}
												</p>

											</div>
											<div className={`card-body ${event.status}`} style={{flex: 1}}>
												<div className="btnGroups">
													<Link to="/guest-list" className='btn-upload' onClick={() => {
															dispatch(setActiveEvent(event))
														}}>
														<i>
															<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M5.5 9L5.5 1M5.5 1L1.5 5M5.5 1L9.5 5" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
															</svg>
														</i>

														<span className="label">
															Upload Guests
														</span>
													</Link>

													<DropdownButton
														title=''
														id="dropdown-menu-align-responsive-1"
														align={{ lg: 'end' }}
													>
														<Dropdown.Item eventKey="1"  onClick={() => {
															dispatch(setEditEvent(event))
														}}>
															<i>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M13.6 4L16 6.4M4 16L4.76584 13.1919C4.81581 13.0087 4.84079 12.9171 4.87914 12.8317C4.91319 12.7558 4.95503 12.6837 5.00399 12.6166C5.05913 12.5409 5.12627 12.4737 5.26054 12.3395L11.4606 6.13941C11.5794 6.02061 11.6388 5.9612 11.7073 5.93895C11.7675 5.91937 11.8325 5.91937 11.8927 5.93895C11.9612 5.9612 12.0206 6.02061 12.1394 6.13941L13.8606 7.86059C13.9794 7.97939 14.0388 8.0388 14.0611 8.1073C14.0806 8.16755 14.0806 8.23245 14.0611 8.29271C14.0388 8.3612 13.9794 8.42061 13.8606 8.53941L7.66054 14.7395C7.52627 14.8737 7.45913 14.9409 7.38345 14.996C7.31625 15.045 7.24415 15.0868 7.16831 15.1209C7.08289 15.1592 6.99129 15.1842 6.80808 15.2342L4 16Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																</svg>
															</i>
															<span className="label">
																Edit Event Details
															</span>
														</Dropdown.Item>


														<Link to="/download-guest" className='dropdown-item' onClick={() => {
															dispatch(setActiveEvent(event))
														}}>
															<i>
																<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M10 1.80269C11.7934 2.84012 13 4.77915 13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 4.77915 2.2066 2.84012 4 1.80269M4.6 7L7 9.4M7 9.4L9.4 7M7 9.4V1" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																</svg>
															</i>
															<span className="label">
																Download Report
															</span>
														</Link>
														
														<Dropdown.Item eventKey="4" onClick={() => leaveEvent(event)}>
															<i>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M3.93652 6.49999C5.14685 4.40769 7.40905 3 10 3C13.866 3 17 6.134 17 9.99998C17 13.866 13.866 17 10 17C7.40905 17 5.14685 15.5923 3.93652 13.5M9.99998 12.8L12.8 9.99998M12.8 9.99998L9.99998 7.19999M12.8 9.99998H3" stroke="#ACA6A6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
																</svg>
															</i>
															<span className="label">
																Leave Event
															</span>
														</Dropdown.Item>
													</DropdownButton>
												</div>

											</div>
										</div>
									</Col>
								)
							})}
						</Row>
					</>
				) : (
					<>
						<div className="cp_table cp_wdth card eventcard">
							<div className='p-2'>
							<Row>
								<Col xs={6}>
									<h5 className='mb-2 mt-3'>My Events</h5>
								</Col>
								<Col xs={6} className='text-end'>
									<Button type='button' variant='dark'  onClick={() => {setEventModalShow(true)}}>
										<i className="ico">
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M7 1V13M1 7H13" stroke="#FFFFFF" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
											</svg>
										</i>
										Create event
									</Button>
								</Col>
							</Row>
							</div>
							<div className="card-body" style={{padding: 0}}>
								<table className="table table-borderless">
									<thead>
										<tr>
											<th style={{width: '10%'}}></th>
											<th style={{width: '30%'}}>Event</th>
											<th style={{width: '15%'}}>Date / Time</th>
											<th style={{width: '15%'}}>Location</th>
											<th style={{width: '10%'}}>Status</th>
											<th style={{width: '20%'}} className='text-center'>Action</th>
										</tr>
									</thead>
									<tbody>
										{events.map((event, index) => {
											return (
												<tr key={index}
												style={{
													cursor: 'pointer',
												}}
												>
													<td style={{width: '10%'}}
													onClick={() => {
														dispatch(setActiveEvent(event))
														navigate('/guest-list')
													}}
													>
														<div style={{width: 80, height: 80, margin: '0 auto'}} >
															<Image src={(event.logo) ? event.logo : require('assets/images/placeholder-event.jpg')} alt="event logo" fluid rounded style={{height: '100%'}} />
														</div>
													</td>
													<td style={{width: '30%'}}
													onClick={() => {
														dispatch(setActiveEvent(event))
														navigate('/guest-list')
													}}
													>
														<strong>{event.name}</strong>
													</td>
													<td style={{width: '15%', fontWeight: 'normal', fontSize: 12}}
													onClick={() => {
														dispatch(setActiveEvent(event))
														navigate('/guest-list')
													}}
													>
														{dayjs(event.start_data).format('MMM DD, YYYY')} - {dayjs(`${event.start_data} ${event.time}`).format('h:mm a')}
													</td>
													<td style={{width: '15%', fontWeight: 'normal', fontSize: 12}}
													onClick={() => {
														dispatch(setActiveEvent(event))
														navigate('/guest-list')
													}}
													>
														{event.location}
													</td>
													<td style={{width: '10%'}}>
														<div 
															className={`status-badge ${event.status} `}
															style={{ margin: '0' }}
														>
															<span className="dot"></span>
															<span className="label">
																{capitalizeWord(event.status)}
															</span>
														</div>
													</td>
													<td style={{width: '20%'}} className='text-end'>
														<div className="btnGroups" style={{flexWrap: 'wrap'}}>
															<Link to="/guest-list" className='btn-upload' 
																style={{
																	display: 'inline-block',
																	width: 'auto',
																	padding: '0 15px',
																}}
																onClick={() => {
																	dispatch(setActiveEvent(event))
																}}
															>
																<i>
																	<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M5.5 9L5.5 1M5.5 1L1.5 5M5.5 1L9.5 5" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																	</svg>
																</i>

																<span className="label">
																	Upload Guests
																</span>
															</Link>

															<DropdownButton
																title=''
																id="dropdown-menu-align-responsive-1"
																align={{ lg: 'end' }}
															>
																<Dropdown.Item eventKey="1"  onClick={() => {
																	dispatch(setEditEvent(event))
																}}>
																	<i>
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M13.6 4L16 6.4M4 16L4.76584 13.1919C4.81581 13.0087 4.84079 12.9171 4.87914 12.8317C4.91319 12.7558 4.95503 12.6837 5.00399 12.6166C5.05913 12.5409 5.12627 12.4737 5.26054 12.3395L11.4606 6.13941C11.5794 6.02061 11.6388 5.9612 11.7073 5.93895C11.7675 5.91937 11.8325 5.91937 11.8927 5.93895C11.9612 5.9612 12.0206 6.02061 12.1394 6.13941L13.8606 7.86059C13.9794 7.97939 14.0388 8.0388 14.0611 8.1073C14.0806 8.16755 14.0806 8.23245 14.0611 8.29271C14.0388 8.3612 13.9794 8.42061 13.8606 8.53941L7.66054 14.7395C7.52627 14.8737 7.45913 14.9409 7.38345 14.996C7.31625 15.045 7.24415 15.0868 7.16831 15.1209C7.08289 15.1592 6.99129 15.1842 6.80808 15.2342L4 16Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																		</svg>
																	</i>
																	<span className="label">
																		Edit Event Details
																	</span>
																</Dropdown.Item>

																{event.status !== EventStatus.ACTIVE ? (
																<Dropdown.Item eventKey="4" onClick={() => setActivatingEvent(event)}>
																	<i>
																		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M4.85 8L6.95 10.1L11.15 5.9M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																		</svg>
																	</i>
																	<span className="label">
																		Activate Event
																	</span>
																</Dropdown.Item>
																) : null}

																<Link to="/download-guest" className='dropdown-item' onClick={() => {
																	dispatch(setActiveEvent(event))
																}}>
																	<i>
																		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M10 1.80269C11.7934 2.84012 13 4.77915 13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 4.77915 2.2066 2.84012 4 1.80269M4.6 7L7 9.4M7 9.4L9.4 7M7 9.4V1" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																		</svg>
																	</i>
																	<span className="label">
																		Download Report
																	</span>
																</Link>
																
																<Link to="/team" className='dropdown-item'>
																	<i>
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M14.3333 16V12M12.3333 14H16.3333M9.66667 12H7C5.75749 12 5.13623 12 4.64618 12.203C3.99277 12.4736 3.47364 12.9928 3.20299 13.6462C3 14.1362 3 14.7575 3 16M12 4.19384C12.9773 4.58943 13.6667 5.54754 13.6667 6.66667C13.6667 7.78579 12.9773 8.7439 12 9.13949M10.6667 6.66667C10.6667 8.13943 9.47276 9.33333 8 9.33333C6.52724 9.33333 5.33333 8.13943 5.33333 6.66667C5.33333 5.19391 6.52724 4 8 4C9.47276 4 10.6667 5.19391 10.6667 6.66667Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																		</svg>
																	</i>
																	<span className="label">
																		Add Team Member
																	</span>
																</Link>
																{event.status !== EventStatus.ARCHIVED && event.status !== EventStatus.EXPIRED ? (
																<Dropdown.Item eventKey="4" onClick={() => updateEventStatus(event.id, EventStatus.ARCHIVED)}>
																	<i>
																		<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M12.1 4V8.92C12.1 9.92809 12.1 10.4321 11.9038 10.8172C11.7312 11.1559 11.4559 11.4312 11.1172 11.6038C10.7321 11.8 10.2281 11.8 9.22 11.8H4.78C3.77191 11.8 3.26786 11.8 2.88282 11.6038C2.54413 11.4312 2.26876 11.1559 2.09619 10.8172C1.9 10.4321 1.9 9.92809 1.9 8.92V4M1.96 1H12.04C12.376 1 12.544 1 12.6724 1.0654C12.7853 1.12292 12.8771 1.21471 12.9346 1.32761C13 1.45595 13 1.62397 13 1.96V3.04C13 3.37603 13 3.54405 12.9346 3.67239C12.8771 3.78529 12.7853 3.87708 12.6724 3.9346C12.544 4 12.376 4 12.04 4H1.96C1.62397 4 1.45595 4 1.32761 3.9346C1.21471 3.87708 1.12292 3.78529 1.0654 3.67239C1 3.54405 1 3.37603 1 3.04V1.96C1 1.62397 1 1.45595 1.0654 1.32761C1.12292 1.21471 1.21471 1.12292 1.32761 1.0654C1.45595 1 1.62397 1 1.96 1ZM5.56 6.1H8.44C8.77603 6.1 8.94405 6.1 9.07239 6.1654C9.18529 6.22292 9.27708 6.31471 9.3346 6.42761C9.4 6.55595 9.4 6.72397 9.4 7.06V7.54C9.4 7.87603 9.4 8.04405 9.3346 8.17239C9.27708 8.28529 9.18529 8.37708 9.07239 8.4346C8.94405 8.5 8.77603 8.5 8.44 8.5H5.56C5.22397 8.5 5.05595 8.5 4.92761 8.4346C4.81471 8.37708 4.72292 8.28529 4.6654 8.17239C4.6 8.04405 4.6 7.87603 4.6 7.54V7.06C4.6 6.72397 4.6 6.55595 4.6654 6.42761C4.72292 6.31471 4.81471 6.22292 4.92761 6.1654C5.05595 6.1 5.22397 6.1 5.56 6.1Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																		</svg>
																	</i>
																	<span className="label">
																		Archive Event
																	</span>
																</Dropdown.Item>
																) : null}
																<Dropdown.Item eventKey="4" onClick={() => setDeletingEvent(event)}>
																	<i>
																		<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M8.8 3.4V2.92C8.8 2.24794 8.8 1.91191 8.66921 1.65521C8.55416 1.42942 8.37058 1.24584 8.14479 1.13079C7.88809 1 7.55206 1 6.88 1H5.92C5.24794 1 4.91191 1 4.65521 1.13079C4.42942 1.24584 4.24584 1.42942 4.13079 1.65521C4 1.91191 4 2.24794 4 2.92V3.4M5.2 6.7V9.7M7.6 6.7V9.7M1 3.4H11.8M10.6 3.4V10.12C10.6 11.1281 10.6 11.6321 10.4038 12.0172C10.2312 12.3559 9.95587 12.6312 9.61718 12.8038C9.23214 13 8.72809 13 7.72 13H5.08C4.07191 13 3.56786 13 3.18282 12.8038C2.84413 12.6312 2.56876 12.3559 2.39619 12.0172C2.2 11.6321 2.2 11.1281 2.2 10.12V3.4" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																		</svg>
																	</i>
																	<span className="label">
																		Delete
																	</span>
																</Dropdown.Item>
															</DropdownButton>
														</div>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>

						<div className="cp_table cp_wdth card eventcard mt-5">
							<div className='p-2'>
								<h5 className='mb-2 mt-3'>Event Collaborations</h5>
							</div>
							<div className="card-body" style={{padding: 0}}>
								<table className="table table-borderless">
									<thead>
										<tr>
											<th style={{width: '10%'}}></th>
											<th style={{width: '30%'}}>Event</th>
											<th style={{width: '15%'}}>Date / Time</th>
											<th style={{width: '15%'}}>Location</th>
											<th style={{width: '10%'}}>Status</th>
											<th style={{width: '20%'}} className='text-center'>Action</th>
										</tr>
									</thead>
									<tbody>
										{(eventsCollaborate.length === 0) && (<tr><td className='text-muted text-center' colSpan={6}>No events available!</td></tr>)}
										{eventsCollaborate.map((event, index) => {
											return (
												<tr key={index}
												style={{
													cursor: 'pointer',
												}}
												>
													<td style={{width: '10%'}}
													onClick={() => {
														dispatch(setActiveEvent(event))
														navigate('/guest-list')
													}}
													>
														<div style={{width: 80, height: 80, margin: '0 auto'}} >
															<Image src={(event.logo) ? event.logo : require('assets/images/placeholder-event.jpg')} alt="event logo" fluid rounded style={{height: '100%'}} />
														</div>
													</td>
													<td style={{width: '30%'}}
													onClick={() => {
														dispatch(setActiveEvent(event))
														navigate('/guest-list')
													}}
													>
														<strong>{event.name}</strong>
													</td>
													<td style={{width: '15%', fontWeight: 'normal', fontSize: 12}}
													onClick={() => {
														dispatch(setActiveEvent(event))
														navigate('/guest-list')
													}}
													>
														{dayjs(event.start_data).format('MMM DD, YYYY')} - {dayjs(`${event.start_data} ${event.time}`).format('h:mm a')}
													</td>
													<td style={{width: '15%', fontWeight: 'normal', fontSize: 12}}
													onClick={() => {
														dispatch(setActiveEvent(event))
														navigate('/guest-list')
													}}
													>
														{event.location}
													</td>
													<td style={{width: '10%'}}>
														<div 
															className={`status-badge ${event.status} `}
															style={{ margin: '0' }}
														>
															<span className="dot"></span>
															<span className="label">
																{capitalizeWord(event.status)}
															</span>
														</div>
													</td>
													<td style={{width: '20%'}} className='text-end'>
														<div className="btnGroups" style={{flexWrap: 'wrap'}}>
															<Link to="/guest-list" className='btn-upload' 
																style={{
																	display: 'inline-block',
																	width: 'auto',
																	padding: '0 15px',
																}}
																onClick={() => {
																	dispatch(setActiveEvent(event))
																}}
															>
																<i>
																	<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M5.5 9L5.5 1M5.5 1L1.5 5M5.5 1L9.5 5" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																	</svg>
																</i>

																<span className="label">
																	Upload Guests
																</span>
															</Link>

															<DropdownButton
																title=''
																id="dropdown-menu-align-responsive-1"
																align={{ lg: 'end' }}
															>
																<Dropdown.Item eventKey="1"  onClick={() => {
																	dispatch(setEditEvent(event))
																}}>
																	<i>
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M13.6 4L16 6.4M4 16L4.76584 13.1919C4.81581 13.0087 4.84079 12.9171 4.87914 12.8317C4.91319 12.7558 4.95503 12.6837 5.00399 12.6166C5.05913 12.5409 5.12627 12.4737 5.26054 12.3395L11.4606 6.13941C11.5794 6.02061 11.6388 5.9612 11.7073 5.93895C11.7675 5.91937 11.8325 5.91937 11.8927 5.93895C11.9612 5.9612 12.0206 6.02061 12.1394 6.13941L13.8606 7.86059C13.9794 7.97939 14.0388 8.0388 14.0611 8.1073C14.0806 8.16755 14.0806 8.23245 14.0611 8.29271C14.0388 8.3612 13.9794 8.42061 13.8606 8.53941L7.66054 14.7395C7.52627 14.8737 7.45913 14.9409 7.38345 14.996C7.31625 15.045 7.24415 15.0868 7.16831 15.1209C7.08289 15.1592 6.99129 15.1842 6.80808 15.2342L4 16Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																		</svg>
																	</i>
																	<span className="label">
																		Edit Event Details
																	</span>
																</Dropdown.Item>


																<Link to="/download-guest" className='dropdown-item' onClick={() => {
																	dispatch(setActiveEvent(event))
																}}>
																	<i>
																		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M10 1.80269C11.7934 2.84012 13 4.77915 13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 4.77915 2.2066 2.84012 4 1.80269M4.6 7L7 9.4M7 9.4L9.4 7M7 9.4V1" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																		</svg>
																	</i>
																	<span className="label">
																		Download Report
																	</span>
																</Link>
																
																<Dropdown.Item eventKey="4" onClick={() => leaveEvent(event)}>
																	<i>
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M3.93652 6.49999C5.14685 4.40769 7.40905 3 10 3C13.866 3 17 6.134 17 9.99998C17 13.866 13.866 17 10 17C7.40905 17 5.14685 15.5923 3.93652 13.5M9.99998 12.8L12.8 9.99998M12.8 9.99998L9.99998 7.19999M12.8 9.99998H3" stroke="#ACA6A6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
																		</svg>
																	</i>
																	<span className="label">
																		Leave Event
																	</span>
																</Dropdown.Item>
															</DropdownButton>
														</div>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</>
				)}

				{eventModalShow && (
					<EventCreate isOpen={eventModalShow} setIsOpen={setEventModalShow} onEventCreated={onEventCreated} setActivatingEvent={setActivatingEvent} />
				)}

				{deletingEvent && (
					<Modal show={true} className="deleteEventModal" onHide={() => setDeletingEvent(null)} aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>
							Delete Event!
							</Modal.Title>
						</Modal.Header>
		
						<Modal.Body className='text-center'>
							<div>
								<p>
									Are you sure you want to delete <strong>{deletingEvent.name}</strong> event with all of its guests
								</p>
	
								<h4 className="mb-5">
									This action cannot be undone
								</h4>
							</div>
	
							<div className={`d-grid actionBtns`}>
								<Button variant="primary" className={`btn-steps cp_delete_btn`} style={{ width: '100%' }} onClick={() => deleteEvent(deletingEvent)}>
									<span className={`step3 d-flex align-items-center justify-content-center`}>
										Yes Delete event
									</span>
								</Button>
							</div>
						</Modal.Body>
					</Modal>
				)}

				{showEventShareModal && (
					<Modal show={showEventShareModal} className="deleteEventModal" onHide={() => setShowEventShareModal(false)} aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>
							Launch Check-in App
							</Modal.Title>
						</Modal.Header>
		
						<Modal.Body className='text-center'>
							<h5 className='mt-3'>Scan the code to open the Check-in App on your iPad or Smartphone</h5>
							<div className='text-center mt-3 mb-2'>
								<img src={require('assets/images/qr-web-link.png')} alt="event qr" width={200} />
							</div>

							<Button type='button' variant='link' className='text-dark mb-4' onClick={switchApp}>Or click here to check-in guest on this device</Button>
	
						</Modal.Body>
					</Modal>
				)}

				{activatingEvent && (
					<EventPurchase event={activatingEvent} onCancel={() => setActivatingEvent(null)} onSuccess={() => {
						batch(() => {
							setActivatingEvent(null); 
							onEventCreated();
						})
					}} />
				)}
			</div>
		</>
	);
};

export default Dashboard;