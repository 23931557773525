
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { batch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { resetUserData, setDeviceName, setLoggedIn, setUserData } from './redux/authSlice';

import { AuthContext } from 'AuthContext';
import AuthRoutes from 'routes/AuthRoutes';
import PrivateRoutes from 'routes/PrivateRoutes';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { Spinner } from 'react-bootstrap';
import { setOnline } from 'redux/interfaceManger';
import { TeamAccessType } from 'types';
import { apiLogout } from 'api/auth';

// https://web.dev/learn/pwa/installation-prompt

const loading = (
	<div className="pt-5 text-center">
		<Spinner animation="border" role="status">
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	</div>
)


const App = (): JSX.Element => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const loggedIn = useAppSelector(state => state.auth.loggedIn);
	const userData = useAppSelector(state => state.auth.userData);
	const dispatch = useAppDispatch();

	useEffect(() => {
		(async () => {

			const checkSession = async () => {
				const session = localStorage.getItem('@userData');

				if (session) {
					const sessionData = JSON.parse(session);

					if (sessionData) {

						const deviceName = localStorage.getItem('@deviceName');

						batch(() => {
							dispatch(setLoggedIn(true));
							dispatch(
								setUserData(sessionData),
							);

							if (deviceName) {
								dispatch(setDeviceName(deviceName))
							}
						});
					}
				}
			};
			await checkSession();

			// wait for sec to update redux data
			setTimeout(() => {
				setIsLoading(false);
			}, 1000);
		})()
		
	// eslint-disable-next-line
	}, []) 

	// check online / offline status
	const onOnline = useCallback(() => {
		console.log('online');
		dispatch(setOnline(true));
	}, [])

	const onOffline = useCallback(() => {
		console.log('onOffline');
		dispatch(setOnline(false));
	}, [])

	useEffect(() => {
		window.addEventListener('online', onOnline, false)
		window.addEventListener('offline', onOffline, false)

		return () => {
			window.removeEventListener('online', onOnline, false)
			window.removeEventListener('offline', onOnline, false)
		}
	}, [onOnline, onOffline])

	

	const logout = async () => {
		await apiLogout();

		localStorage.removeItem('@userData');
		localStorage.removeItem('@apiToken');
		localStorage.clear();

		batch(() => {
			dispatch(setLoggedIn(false));
			dispatch(resetUserData());
		});

	}

	const switchApp = () => {
		if (userData) {
			console.log(userData)
			if (userData.roleName === 'admin') {
				dispatch(setUserData({
					...userData,
					roleName: 'event-manager'
				}))
			} else {
				dispatch(setUserData({
					...userData,
					roleName: 'admin'
				}))
			}
		}
	}

	if (isLoading) {
		return loading;
	}

	return (
		<div className="App">
			<ThemeProvider>
				<AuthContext.Provider value={{
					logout,
					switchApp,
				}}>
					<BrowserRouter>
						<Suspense fallback={loading}>
							{loggedIn ? <PrivateRoutes /> : <AuthRoutes />}
						</Suspense>
					</BrowserRouter>
				</AuthContext.Provider>
			</ThemeProvider>
		</div>
	);
}

export default React.memo(App);
