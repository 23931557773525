import React, { useContext, useEffect, useState } from 'react';
import { apiChargeEventCredits, apiGetPackages } from 'api/payment';
import { AvailableCurrencies } from 'constant';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Event, Package } from "types";
import CardForm from './StripePayment/CardForm';
import { useAppSelector } from 'redux/hooks';
import { batch } from 'react-redux';




type EventPurchaseProps = {
	event: Event
	onSuccess: () => void
	onCancel: () => void
}

const EventPurchase = ({
	event,
	onSuccess,
	onCancel,
}: EventPurchaseProps): JSX.Element => {
	const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
	const [currency, setCurrency] = useState<string>(AvailableCurrencies[0]);
	const [packages, setPackages] = useState<Package[]>([]);
	const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false)
	const [paymentSuccessResponse, setPaymentSuccessResponse] = useState<any>({id: 0})
	const [eventCredits, setEventCredits] = useState<number>(0);
	const [useEventCredits, setUseEventCredits] = useState<boolean>(false);
	const [errMsg, setErrMsg] = useState<string>('')
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async () => {
			const response = await apiGetPackages()

			if (response.success) {
				setPackages(response.data.packages)
				setEventCredits(response.data.event_credits)
			}
		})()
	}, []);

	const paymentSuccess = (paymentResponseData: any) => {
		setIsPaymentSuccess(true)
		setPaymentSuccessResponse(paymentResponseData)
	}

	const selectedPackageForCredit = async (selectedPackage: Package) => {
		batch(() => {
			setErrMsg('');
			setIsLoading(true);
		})
		const response = await apiChargeEventCredits(event.id, selectedPackage.id);
		
		setIsLoading(false);
		if (response.success) {
			paymentSuccess(response.data);
		} else {
			setErrMsg(response.message);
		}

	}

	const getPackageAmount = (p: Package) => {

		if (useEventCredits) {
			if (p.id == 1) {
				return '5 credits';
			} else {
				return '10 credits';
			}
		}

		let amount = p.amount_usd;

		if (currency === 'HKD') {
			amount = p.amount_hkd;
		}
		else if (currency === 'SGD') {
			amount = p.amount_sgd;
			return `S$ ${amount}`
		}

		return new Intl.NumberFormat('en-US', {
			style: 'currency', 
			currency: currency, 
			minimumFractionDigits: 0, 
			maximumFractionDigits: 0
		}).format(amount);
	}

	return (
		<>
		<Modal show={true} className="deleteEventModal" onHide={onCancel} aria-labelledby="contained-modal-title-vcenter" centered size='xl' style={{ zIndex: 99999 }}>
			<Modal.Header closeButton>
				<Modal.Title>
				Activate Event
				</Modal.Title>
			</Modal.Header>

			<Modal.Body className='text-center bg-light'>
				{isPaymentSuccess ? (
					<div className='pt-5 pb-5'>
						<h1 className='mb-4'>
							<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="80px" height="80px">
								<path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 34.988281 14.988281 A 1.0001 1.0001 0 0 0 34.171875 15.439453 L 23.970703 30.476562 L 16.679688 23.710938 A 1.0001 1.0001 0 1 0 15.320312 25.177734 L 24.316406 33.525391 L 35.828125 16.560547 A 1.0001 1.0001 0 0 0 34.988281 14.988281 z"
									fill='#19BD9D'
								/>
							</svg>
							{` `}
							Payment Successful
						</h1>
						<h4 className='mb-4'>Your event has been activated!</h4>
						<div className='active-event-popup mb-4'>
							<div className="statusBox ">
								<span className="dot"></span>
								<span className="label">
									Active
								</span>
							</div>
						</div>
						<p className='text-muted mb-0'>Transaction Number: {paymentSuccessResponse.id}</p>
						<Row>
							<Col md={4}></Col>
							<Col md={4}><hr /></Col>
						</Row>
						<Button type='button' variant='dark' onClick={onSuccess} size='lg'>Continue</Button>
					</div>
				) : (
					<>
					{(selectedPackage === null) ? (
					<>
						<Row>
							<Col md={3}>
								<Form.Select aria-label="Default select example" value={currency} onChange={e => setCurrency(e.target.value)}>
									{AvailableCurrencies.map(c => {
										return (
											<option key={c} value={c}>{c}</option>
										)
									})}
								</Form.Select>
							</Col>
						</Row>
	
						<div className='mt-3 d-flex justify-content-center align-items-center gap-2'>
							<h5 className='text-center mb-0'>You have <strong>{eventCredits}</strong> credit(s). </h5>
							<Form.Group className="d-flex align-items-center" style={{
								border: '1px solid #aca6a6',
								padding: '1rem',
								borderRadius: '4px',
								cursor: 'pointer',
							}}>
								<Form.Label htmlFor="event-credits-switch" className="m-0 me-2">Use Credits</Form.Label>
								<Form.Check type="switch" 
									id="event-credits-switch" 
									checked={useEventCredits} 
									onChange={() => setUseEventCredits(!useEventCredits)} 
								/>
							</Form.Group>
						</div>

						{errMsg && <div className="alert alert-danger mt-4">{errMsg}</div>}

						<div className='packages-container'>
							{(packages.length === 0) ? (
								<div className="pt-5 text-center">
									<Spinner animation="border" role="status">
										<span className="visually-hidden">Loading...</span>
									</Spinner>
								</div>
							) : null}
							{packages.map(p => {
								return (
									<div key={p.id} className={`package-item`}
									>
										<div className='p-4'>
											<h4>{p.title}</h4>
											<p style={{color: '#757575'}}>{p.description}</p>
											<h1>{getPackageAmount(p)}</h1>
											
											<div className='d-grid mb-3 mt-4'>
												<Button type='button' 
													variant='dark' 
													size='lg'
													onClick={() => {
														if (useEventCredits) {
															selectedPackageForCredit(p)
														} else {
															setSelectedPackage(p)
														}
													}}
													disabled={isLoading}
												>SELECT</Button>
											</div>
											
											{(p.features && p.features.hasOwnProperty('main') && p.features.main.length) ? (
												<ul>
													{p.features.main.map(f => {
														return (
															<li key={f}>
																<i>
																	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M4.85 8L6.95 10.1L11.15 5.9M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																	</svg>
																</i>
																<span>{f}</span>
															</li>
														)
													})}
												</ul>
											) : null}
										</div>

										{(p.features && p.features.hasOwnProperty('secondary')) ? (
											<>
											<p style={{
												backgroundColor: '#d9d9d9',
												color: '#2c292c',
												marginBottom: 0,
												paddingLeft: '1.5rem',
												paddingRight: '1.5rem',
												paddingTop: '3px',
												paddingBottom: '3px',
												textTransform: 'uppercase',
												fontWeight: 600
											}}>Features:</p>
											<div className='p-4'>
												<p className='small'>{p.features.secondary.title}</p>
												<ul>
													{p.features.secondary.features.map(f => {
														return (
															<li key={f}>
																<i>
																	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M4.85 8L6.95 10.1L11.15 5.9M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#ACA6A6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
																	</svg>
																</i>
																<span>{f}</span>
															</li>
														)
													})}
												</ul>
											</div>
											</>
										) : null}
									</div>
								)
							})}
						</div>
					</>
					) : (
					<>
					<CardForm event={event} selectedPackage={selectedPackage} currency={currency} packageAmount={getPackageAmount(selectedPackage)} onCancel={onCancel} onSuccess={paymentSuccess} />
					</>
					)}
					</>
				)}
			</Modal.Body>
		</Modal>
		</>
	)
}

export default EventPurchase;