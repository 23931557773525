import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { batch } from 'react-redux';
import { unsetActiveEvent } from 'redux/eventSlice';
import { setHeaderTitle } from 'redux/interfaceManger';
import { Event, TeamCreateUserData, TeamInviteUserData } from 'types';
import { apiCreateTeamMember, apiInviteTeamMember } from 'api/team';
import { apiGetEvent } from 'api/events';
import { EventStatus, TeamAccess, TeamRole } from 'constant';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import PasswordField from 'components/PasswordField';



const TeamAdd = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userData = useAppSelector(state => state.auth.userData);
	const [events, setEvents] = useState<Event[]>([]);
	const [createUserData, setCreateUserData] = useState<TeamCreateUserData>({
		username: '',
		password: '',
		access: TeamAccess.APP,
		role: TeamRole.ASSISTANT,
		events: []
	})
	const [inviteUserData, setInviteUserData] = useState<TeamInviteUserData>({
		email: '',
		access: TeamAccess.APP,
		role: TeamRole.ASSISTANT,
		events: []
	})

	useEffect(() => {
		batch(() => {
			dispatch(unsetActiveEvent())
			dispatch(setHeaderTitle('Team'))
		})
	}, [])


	useEffect(() => {

		(async () => {
			const response = await apiGetEvent([EventStatus.ACTIVE, EventStatus.TESTING])

			if (response.success) {
				setEvents(response.data)
			}
		})()

	}, [])

	
	const submitCreateUser = async () => {
		const response = await apiCreateTeamMember(createUserData)

		if (response.success) {
			navigate('/team')
		} else {
			alert(response.message)
		}
	}

	const submitInviteUser = async () => {
		const response = await apiInviteTeamMember(inviteUserData)

		if (response.success) {
			navigate('/team')
		} else {
			alert(response.message)
		}
	}


	return (
		<div className="">
			<h4 className='text-center'>Add Team Member</h4>

			<div className="team-add-page">
				<div className='section-invite-user cp_tabs_contentbx' style={{width: '500px'}}>
					<h5 className='mb-4'>Invite Team Member</h5>

					<Form.Group className="mb-3" controlId="eventCreateModal">
						<Form.Label>Email Address</Form.Label>
						<Form.Control type="email" value={inviteUserData.email} onChange={e => setInviteUserData({...inviteUserData, email: e.target.value})} />
					</Form.Group>
					<p>An email will be sent to this person, inviting them to accept the invitation to join your team.</p>
					<Form.Group className="mb-3" controlId="eventCreateModal">
						<Form.Label>Role</Form.Label>
						<Form.Select value={inviteUserData.role} onChange={e => {
							if (e.target.value === TeamRole.MANAGER) {
								setInviteUserData({...inviteUserData, access: TeamAccess.BACKEND, role: e.target.value})
							} else {
								setInviteUserData({...inviteUserData, access: TeamAccess.APP, role: e.target.value})
							}
						}}>
							<option value={TeamRole.MANAGER}>Manager</option>
							<option value={TeamRole.ASSISTANT}>Assistant</option>
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="eventCreateModal">
						<Form.Label>Events</Form.Label>
						<Select
							isMulti={true}
							className="react-select-container"
							classNamePrefix="react-select"
							onChange={e => {
								setInviteUserData({...inviteUserData, events: [...e.map(event => event.value)]})
							}}
							value={[...events.filter(e => {
								return inviteUserData.events.find(event_id => event_id === e.id)
							}).map(e => {
								return {
									value: e.id,
									label: e.name,
									image: (e.logo) ? e.logo : require('assets/images/placeholder-event.jpg'),
								}
							})]}
							options={[...events.map(e => {
								return {
									value: e.id,
									label: e.name,
									image: (e.logo) ? e.logo : require('assets/images/placeholder-event.jpg'),
								}
							})]}
							formatOptionLabel={event => (
								<div className="event-option">
									<img src={event.image} alt={event.label} style={{
									width: '20px',
									height: '20px',
									borderRadius: '50%'
									}} />
									{` `}
									<span>{event.label}</span>
								</div>
							)}
						/> 
					</Form.Group>

					<div className="text-center">
						<Button type='button' variant='dark' className='mt-4' onClick={submitInviteUser}>Invite team member</Button>
					</div>
				</div>
				<div className='divider'>
					<span>OR</span>
				</div>
				<div className='section-add-user cp_tabs_contentbx' style={{width: '500px'}}>
					<h5 className='mb-4'>Create Username / Password</h5>

					<Form.Group className="mb-3" controlId="eventCreateModal">
						<Form.Label>Username</Form.Label>
						<Form.Control type="text" value={createUserData.username} onChange={e => setCreateUserData({...createUserData, username: e.target.value})} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="eventCreateModal">
						<Form.Label>Password</Form.Label>
						<PasswordField value={createUserData.password} onChange={e => setCreateUserData({...createUserData, password: e.target.value})} enableValidation={true} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="eventCreateModal">
						<Form.Label>Role</Form.Label>
						<Form.Select value={createUserData.role} onChange={e => {
							if (e.target.value === TeamRole.MANAGER) {
								setCreateUserData({...createUserData, access: TeamAccess.BACKEND, role: e.target.value})
							} else {
								setCreateUserData({...createUserData, access: TeamAccess.APP, role: e.target.value})
							}
						}}>
							{/* <option value={TeamRole.MANAGER}>Manager</option> */}
							<option value={TeamRole.ASSISTANT}>Assistant</option>
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="eventCreateModal">
						<Form.Label>Events</Form.Label>
						<Select
							isMulti={true}
							className="react-select-container"
							classNamePrefix="react-select"
							onChange={e => {
								setCreateUserData({...createUserData, events: [...e.map(event => event.value)]})
							}}
							value={[...events.filter(e => {
								return createUserData.events.find(event_id => event_id === e.id)
							}).map(e => {
								return {
									value: e.id,
									label: e.name,
									image: (e.logo) ? e.logo : require('assets/images/placeholder-event.jpg'),
								}
							})]}
							options={[...events.map(e => {
								return {
									value: e.id,
									label: e.name,
									image: (e.logo) ? e.logo : require('assets/images/placeholder-event.jpg'),
								}
							})]}
							formatOptionLabel={event => (
								<div className="event-option">
									<img src={event.image} alt={event.label} style={{
									width: '20px',
									height: '20px',
									borderRadius: '50%'
									}} />
									{` `}
									<span>{event.label}</span>
								</div>
							)}
						/> 
					</Form.Group>

					<div className="text-center">
						<Button type='button' variant='dark' className='mt-4' onClick={submitCreateUser}>Add team member</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TeamAdd;