import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventStatus } from 'constant';
import { Event, EventStatusType } from 'types';


type EventSliceProps = {
	activeEvent: Event | null
	editEvent: Event | null
	eventsListStatus: EventStatusType[]
	testEventGuestLimit: number
	eventExpireDays: number
}

const initialState: EventSliceProps = {
	activeEvent: null,
	editEvent: null,
	eventsListStatus: [...Object.values(EventStatus)],
	testEventGuestLimit: 20,
	eventExpireDays: 7,
}

export const eventSlice = createSlice({
	name: 'events',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setActiveEvent(state: EventSliceProps, action: PayloadAction<Event>) {
			state.activeEvent = action.payload;
		},
		unsetActiveEvent(state: EventSliceProps) {
			state.activeEvent = null;
		},
		setEditEvent(state: EventSliceProps, action: PayloadAction<Event>) {
			state.editEvent = action.payload;
		},
		unsetEditEvent(state: EventSliceProps) {
			state.editEvent = null;
		},
		setEventsListStatus(state: EventSliceProps, action: PayloadAction<EventStatusType[]>) {
			state.eventsListStatus = [...action.payload];
		},
		setTestEventGuestLimit(state: EventSliceProps, action: PayloadAction<number>) {
			state.testEventGuestLimit = action.payload;
		},
		setEventExpireDays(state: EventSliceProps, action: PayloadAction<number>) {
			state.eventExpireDays = action.payload;
		}
	},
});


export const {
	setActiveEvent,
	unsetActiveEvent,
	setEditEvent,
	unsetEditEvent,
	setEventsListStatus,
	setTestEventGuestLimit,
	setEventExpireDays,
} = eventSlice.actions;

export default eventSlice.reducer;
