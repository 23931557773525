import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { toast } from 'react-toastify';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { unsetActiveEvent } from 'redux/eventSlice';
import { setHeaderTitle } from 'redux/interfaceManger';
import Invoices from './Invoices';
import { apiChangePassword, apiGetBillingAddress, apiUpdateBillingAddress, apiUpdateProfile, apiUpdateProfileImage } from 'api/auth';
import { Image } from 'react-bootstrap';
import { setUserData } from 'redux/authSlice';
import PasswordField from 'components/PasswordField';

const countries = require('assets/countries.json');

const Accounts = () => {
	const dispatch = useAppDispatch();
	const userData = useAppSelector(state => state.auth.userData);
	const [isEditProfile, setIsEditProfile] = useState<boolean>(false);
	const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
	const [isEditAccount, setIsEditAccount] = useState<boolean>(false);
	const [isEditBilling, setIsEditBilling] = useState<boolean>(false);

	console.log({userData})

	const [img, setImg] = useState<File | null>(null);
	const imgURL = (img !== null) ? URL.createObjectURL(img) : null;
	const [profileData, setProfileData] = useState<any>({
		name: userData?.name || '',
		email: userData?.email || '',
		image: userData?.image || null,
		has_mfa: userData?.has_mfa || 0,
	})
	const [passwordData, setPasswordData] = useState<any>({
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
	})

	const [billingData, setBillingData] = useState<any>({
		company_name: '',
		attention: '',
		address: '',
		country: '',
	})
	

	useEffect(() => {
		batch(() => {
			dispatch(unsetActiveEvent())
			dispatch(setHeaderTitle('Accounts'))
		})
	}, [])


	useEffect(() => {
		(async () => {
			const response = await apiGetBillingAddress()

			// console.log({billing: response})
			if (response.success) {
				setBillingData({
					company_name: (response.data.company_name) ? response.data.company_name : '',
					attention: (response.data.attention) ? response.data.attention : '',
					address: (response.data.address) ? response.data.address : '',
					country: (response.data.country) ? response.data.country : '',
				})
			}	
		})()
	}, [])

	const updateProfile = async () => {
		const response = await apiUpdateProfile(profileData.name, profileData.email, profileData.has_mfa)

		if (response.success) {
			setIsEditProfile(false)
			if (userData) {
				dispatch(
					setUserData({
						...userData,
						name: response.data.name,
						email: response.data.email,
						has_mfa: response.data.has_mfa,
					}),
				);
			}
			toast.success(response.message)
		} else {
			toast.error(response.message)
		}
	}

	const toggleProfileMFA = async (mfa_status: any) => {
		const response = await apiUpdateProfile(profileData.name, profileData.email, mfa_status)

		if (response.success) {
			setIsEditProfile(false)
			if (userData) {
				dispatch(
					setUserData({
						...userData,
						name: response.data.name,
						email: response.data.email,
						has_mfa: response.data.has_mfa,
					}),
				);
				setProfileData({...profileData, has_mfa: mfa_status})
			}
			toast.success(response.message)
		} else {
			toast.error(response.message)
		}
	}

	const updateProfileImage = async (image: File) => {
		if (image && userData) {
			const response = await apiUpdateProfileImage(image)
	
			if (response.success) {
				setIsEditProfile(false)
				dispatch(
					setUserData({
						...userData,
						image: response.data.image,
					}),
				);
				toast.success(response.message)
			} else {
				toast.error(response.message)
			}
		}
	}

	const updatePassword = async () => {
		const response = await apiChangePassword(
			passwordData.currentPassword,
			passwordData.newPassword,
			passwordData.confirmPassword
		)

		if (response.success) {
			setIsChangePassword(false)
			toast.success(response.message)
		} else {
			toast.error(response.message)
		}
	}

	const updateAccountDetails = async () => {
		setIsEditAccount(false)
	}

	const onPurchaseCredit = async () => {
		
	}

	const updateBillingInfo = async () => {
		const response = await apiUpdateBillingAddress(
			billingData.company_name,
			billingData.attention,
			billingData.address,
			billingData.country,
		)

		if (response.success) {
			setIsEditBilling(false)
			toast.success(response.message)
		} else {
			toast.error(response.message)
		}
	}

	const getAvatarName = (data: {name: string, email: string}) => {

		if (data.name && data.name !== '') {
			let s = '';
			data.name.split(' ').map(t => {
				if (t && t !== '' && t.trim() !== '' && s.length < 2) {
					s += t[0]
				}
			})
			return s.toUpperCase();
		}
		else if (data.email) {
			return data.email[0].toUpperCase();
		}
		return '-';
	}

	return (
		<Tabs defaultActiveKey="accounts" id="uncontrolled-tab-example" className="mb-3 cp_tab">
			<Tab eventKey="accounts" title="Accounts">
				<div className="cp_tabs_contentbx">
					

					<Row>
						<Col sm={2}>
							<div className="profile-image-container">
								{(profileData.image || imgURL) ? (
									<Image 
										src={(imgURL) ? imgURL : (profileData.image ? profileData.image : require('assets/images/placeholder-event.jpg'))} 
										roundedCircle 
										fluid
									/>
								) : (
									<div className='image-avatar'>
										{getAvatarName({name: profileData.name, email: profileData.email})}
									</div>
								)}
								
								<div className="image-upload-btn">
									<input
										type="file"
										name="file"
										placeholder="Upload File"
										accept="image/*"
										required
										onChange={e => {
											if (e.target.files !== null) {
												setImg(e.target.files[0]);
												updateProfileImage(e.target.files[0])
											}
										}}
									/>
									
									<svg width="60" height="60" viewBox="10 5 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g >
											<path d="M28.4 14L30 15.6M22 22L22.5106 20.1279C22.5439 20.0058 22.5605 19.9447 22.5861 19.8878C22.6088 19.8372 22.6367 19.7892 22.6693 19.7444C22.7061 19.6939 22.7508 19.6492 22.8404 19.5596L26.9737 15.4263C27.0529 15.3471 27.0925 15.3075 27.1382 15.2926C27.1784 15.2796 27.2216 15.2796 27.2618 15.2926C27.3075 15.3075 27.3471 15.3471 27.4263 15.4263L28.5737 16.5737C28.6529 16.6529 28.6925 16.6925 28.7074 16.7382C28.7204 16.7784 28.7204 16.8216 28.7074 16.8618C28.6925 16.9075 28.6529 16.9471 28.5737 17.0263L24.4404 21.1596C24.3508 21.2492 24.3061 21.2939 24.2556 21.3307C24.2108 21.3633 24.1628 21.3912 24.1122 21.4139C24.0553 21.4395 23.9942 21.4561 23.8721 21.4894L22 22Z" 
											stroke="#FFFFFF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
										</g>
									</svg>
								</div>
							</div>
						</Col>
						<Col sm={10}>
							<div className="cp_head_btn">
								<h3 className='head2'>Profile</h3>
								{isEditProfile ? (
									<button type='button' className="update_btn" onClick={updateProfile}>Update details</button>
								) : (
									<button type='button' className="update_btn" onClick={() => setIsEditProfile(true)}>Edit details</button>
								)}
							</div>
							<div className="cp_text_bx">
								<ul className="cp_value">
									<li>
										<p>Full Name</p>
										<input type="text" value={profileData.name} disabled={!isEditProfile} onChange={e => setProfileData({...profileData, name: e.target.value})} />
									</li>
									<li>
										<p>Email Address</p>
										<input type="text" value={profileData.email} disabled={!isEditProfile} onChange={e => setProfileData({...profileData, email: e.target.value})} />
									</li>

									<li></li>
								</ul>
							</div>

							<div className="cp_head_btn">
								<h3 className='head2 mb-0'>Password</h3>
								{isChangePassword ? (
									<></>
								) : (
									<button type='button' className="update_btn pass" onClick={() => setIsChangePassword(true)}>Change Password</button>
								)}
							</div>
							<div className="cp_text_bx">
								<ul className="cp_value">
									{isChangePassword ? (
										<>
											<li className='mt-3 d-flex align-items-center'>
												<p>Current Password</p>
												<div style={{flex: 1}}>
													<PasswordField value={passwordData.currentPassword} onChange={e => setPasswordData({...passwordData, currentPassword: e.target.value})} />
												</div>
											</li>
											<li className='d-flex align-items-center'>
												<p>New Password</p>
												<div style={{flex: 1}}>
													<PasswordField value={passwordData.newPassword} onChange={e => setPasswordData({...passwordData, newPassword: e.target.value})} enableValidation={true} />
												</div>
											</li>
											<li className='d-flex align-items-center'>
												<p>Confirm Password</p>
												<div style={{flex: 1}}>
													<PasswordField value={passwordData.confirmPassword} onChange={e => setPasswordData({...passwordData, confirmPassword: e.target.value})} matchWithValue={passwordData.newPassword} />
												</div>
											</li>
											<li className='text-end no-border'>
												<button type='button' className="update_btn pass" onClick={updatePassword}>Update Password</button>
											</li>
										</>
									) : (
										<></>
									)}

									<li></li>
								</ul>
							</div>

							{(userData && userData.is_member === 0) ? (
							<Row>
								<Col md={8}>
									<Form.Check id="mfa_check" type="checkbox" 
										name="enable_mfa" 
										label="Enable Multi-Factor Authentication (MFA)" 
										style={{fontWeight: 600}}
										checked={profileData.has_mfa === 1}
										onChange={(e) => {
											console.log('toggleProfileMFA', (e.target.checked) ? 1 : 0)
											toggleProfileMFA((e.target.checked) ? 1 : 0)
										}}
									/>
									<p className='text-muted'>Increase the security of your account by enabling Multi-Factor Authentication (MFA). With MFA, you will be required to provide an additional verification method along with your password when logging in, adding an extra layer of protection to your account.</p>
								</Col>
							</Row>
							) : null}
						</Col>
					</Row>

				</div>

				<div className="cp_tabs_contentbx">
					<div className="cp_head_btn">
						<h3 className='head2'>Account Details</h3>
						{isEditAccount ? (
							<button type='button' className="update_btn" onClick={updateAccountDetails}>Update details</button>
						) : (
							<button type='button' className="update_btn" onClick={() => setIsEditAccount(true)}>Edit details</button>
						)}
					</div>
					<div className="cp_text_bx">
						<ul className="cp_value">
							<li>
								<p>Account ID</p>
								<input type="text" value={userData?.id} disabled />
							</li>
							<li className="cp_btn-position">
								<p>Credits</p>
								<input type="text" value={userData?.event_credits} disabled />
								<a href='https://www.checkinpax.com/pricing' target='_blank' className="update_btn pass">Purchase Credits</a>
							</li>
						</ul>

						<p>How many events do you organize per year?</p>

						<Form.Select className='cp_tabselect' aria-label="Default select example" disabled={!isEditAccount}>
							<option value="1">1</option>
							<option value="5">5</option>
							<option value="10">10+</option>
						</Form.Select>
					</div>
				</div>

				<div className="cp_tabs_contentbx">
					<div className="cp_head_btn">
						<h3 className='head2'>Billing Address</h3>
						{isEditBilling ? (
							<button type='button' className="update_btn" onClick={updateBillingInfo}>Update details</button>
						) : (
							<button type='button' className="update_btn" onClick={() => setIsEditBilling(true)}>Edit details</button>
						)}
					</div>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="2">Company Name</Form.Label>
						<Col sm="10">
							<Form.Control plaintext={!isEditBilling} readOnly={!isEditBilling} value={billingData.company_name} onChange={e => setBillingData({...billingData, company_name: e.target.value})} />
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="2">Attention</Form.Label>
						<Col sm="10">
							<Form.Control plaintext={!isEditBilling} readOnly={!isEditBilling} value={billingData.attention} onChange={e => setBillingData({...billingData, attention: e.target.value})} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="2">Address</Form.Label>
						<Col sm="10">
							<Form.Control
								as="textarea"
								plaintext={!isEditBilling} readOnly={!isEditBilling}
								style={{ height: '100px' }}
								value={billingData.address} onChange={e => setBillingData({...billingData, address: e.target.value})}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="2">Country</Form.Label>
						<Col sm="10">
							<Form.Control as="select" aria-label="Default select example" plaintext={!isEditBilling} readOnly={!isEditBilling} value={billingData.country} onChange={e => setBillingData({...billingData, country: e.target.value})}>
								<option value="">- select -</option>
								{countries.map((country: {name: string, code: string}, i: number) => {
									return (
										<option key={i} value={country.code}>{country.name}</option>
									)
								})}
								<option value="UK">UK</option>
							</Form.Control>
						</Col>
					</Form.Group>
				</div>
			</Tab>

			<Tab eventKey="Invoice" title="Invoices">
				<div className="cp_table cp_invoice cp_wdth cp_tab_h cp_blck">
					<Invoices />
				</div>
			</Tab>
		</Tabs>
	);
};

export default Accounts;