import { ResponseObject, Event, EventDataField, EventStatusType, Package } from "types";
import apiService from "services/ApiService";
import cacheService from "services/CacheService";


type GetPackagesResponse = {
	event_credits: number
	packages: Package[]
}

export const apiGetPackages = async (): Promise<ResponseObject<GetPackagesResponse>> => {
	return await apiService.get(`/packages`);
}

export const apiGetStripeConfig = async (): Promise<ResponseObject<string>> => {
	return await apiService.get(`/stripe-config`);
}

export const apiChargeEventPayment = async (
	eventId: string | number,
	packageId: number,
	paymentMethodId: string,
	currency: string,
): Promise<ResponseObject<null>> => {
	return await apiService.post(`/charge-event-payment/${eventId}`, {
		package_id: packageId,
		payment_method_id: paymentMethodId,
		currency: currency,
	});
}

export const apiChargeEventCredits = async (
	eventId: string | number,
	packageId: number,
): Promise<ResponseObject<null>> => {
	return await apiService.post(`/charge-event-credits/${eventId}`, {
		package_id: packageId,
	});
}